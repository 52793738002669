<template>
  <div v-if="orgs.length > 0">
    <p class="text-xl font-medium">Organizations</p>
    <ul class="space-y-4 my-4">
      <li
        v-for="org in orgs"
        class="cursor-pointer"
        @click="
          () => (linkTo(org) === $route.fullPath ? emit('close') : undefined)
        "
      >
        <NuxtLink
          :href="
            linkTo(org) === $route.fullPath ? undefined : linkTo(org) + '?s=qs'
          "
        >
          <OrganizationCard :org="org" large no-link no-follow />
        </NuxtLink>
      </li>
    </ul>
    <NamiDivider />
  </div>
</template>

<script setup lang="ts">
import type { OrganizationEntity } from "~/src/api";

defineProps<{
  orgs: OrganizationEntity[];
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();
</script>
