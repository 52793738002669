<template>
  <HeadlessPopover class="relative">
    <div @mouseenter="open" @mouseleave="close">
      <slot name="opener" />

      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0 -translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-1"
      >
        <div v-if="isOpen">
          <HeadlessPopoverPanel
            static
            class="absolute left-1/2 z-10 mt-3 flex w-screen max-w-max -translate-x-1/2 px-4"
          >
            <div
              class="w-screen max-w-md flex-auto overflow-hidden rounded-md bg-white dark:bg-neutral-900 text-base leading-6 shadow-lg ring-1 ring-neutral-900/5"
            >
              <div class="overflow-hidden">
                <div
                  v-for="item in items"
                  :key="item.name"
                  class="group relative flex gap-x-4 py-2 px-4 hover:bg-neutral-100 dark:hover:bg-neutral-800 transition"
                >
                  <div
                    class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-neutral-50 dark:bg-neutral-900 group-hover:bg-white dark:group-hover:bg-neutral-700 transition"
                  >
                    <component
                      :is="item.icon"
                      class="h-6 w-6 text-neutral-500 group-hover:text-nami-comi-blue transition"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <TheNuxtLink
                      @click="isOpen = false"
                      :to="item.href"
                      class="font-semibold hover:text-neutral-900 dark:hover:text-neutral-100 active:text-black dark:active:text-white"
                    >
                      {{ item.name }}
                      <span class="absolute inset-0" />
                    </TheNuxtLink>
                    <p class="mt-1 text-neutral-500">
                      {{ item.description }}
                    </p>
                  </div>
                </div>
              </div>
              <div
                class="grid grid-cols-2 divide-x divide-neutral-900/5 bg-neutral-50 dark:bg-neutral-900"
              >
                <TheNuxtLink
                  v-for="item in callsToAction"
                  :key="item.name"
                  :to="item.href"
                  class="flex items-center justify-center gap-x-2.5 p-3 font-semibold hover:text-neutral-900 dark:hover:text-neutral-100 active:text-black dark:active:text-white hover:bg-neutral-100"
                >
                  <component
                    :is="item.icon"
                    class="h-5 w-5 flex-none text-neutral-400"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </TheNuxtLink>
              </div>
            </div>
          </HeadlessPopoverPanel>
        </div>
      </transition>
    </div>
  </HeadlessPopover>
</template>

<script setup lang="ts">
import { IconChevronDown } from "@tabler/icons-vue";

type Item = {
  name: string;
  description?: string;
  href?: string;
  icon: typeof IconChevronDown;
};

defineProps<{
  items: Item[];
}>();

const isOpen = ref(false);

const callsToAction: Item[] = [];

let timer: NodeJS.Timeout | null = null;

function open() {
  isOpen.value = true;

  if (timer) {
    clearTimeout(timer);
    timer = null;
  }
}

function close() {
  if (timer) {
    clearInterval(timer);
  }

  timer = setTimeout(() => {
    isOpen.value = false;
    timer = null;
  }, 300);
}
</script>
