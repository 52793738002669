<template>
  <div v-if="users.length > 0">
    <p class="text-xl font-medium">Users</p>
    <ul class="space-y-4 my-4">
      <li
        v-for="user in users"
        class="cursor-pointer"
        @click="
          () => (linkTo(user) === $route.fullPath ? emit('close') : undefined)
        "
      >
        <NuxtLink
          :href="
            linkTo(user) === $route.fullPath
              ? undefined
              : linkTo(user) + '?s=qs'
          "
        >
          <UserCard :user="user" no-link class="input-border px-2" />
        </NuxtLink>
      </li>
    </ul>
    <NamiDivider />
  </div>
</template>

<script setup lang="ts">
import type { UserEntity } from "~/src/api";

defineProps<{
  users: UserEntity[];
}>();

const emit = defineEmits<{
  (e: "close"): void;
}>();
</script>
