<template>
  <HeadlessMenu as="div" class="relative" v-slot="{ open }">
    <HeadlessMenuButton class="flex items-center">
      <UserAvatar
        :user="userEntity ?? undefined"
        :size="40"
        :user-icon="IconUserX"
        no-link
      />
    </HeadlessMenuButton>
    <div v-show="open">
      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <HeadlessMenuItems
          static
          :class="'right-0 origin-top-right'"
          class="font-normal absolute z-10 max-w-[16rem] mt-2 divide-y divide-neutral-200 dark:divide-neutral-800 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none bg-white dark:bg-neutral-900"
        >
          <HeadlessMenuItem v-slot="{ close }" v-if="userEntity">
            <div
              class="cursor-pointer"
              @click="() => (navigateTo(userHref), close())"
            >
              <UserCard
                class="px-2 !rounded-none dark:hover:bg-neutral-800 dark:active:bg-neutral-700 hover:bg-neutral-100 active:bg-neutral-200"
                :user="userEntity"
                no-link
              />
            </div>
          </HeadlessMenuItem>
          <div
            v-for="(submenu, index) in dropdownHeadlessMenu"
            :key="index"
            class="py-1"
          >
            <!-- Will throw an error if it's a template. So wrapping it inside a div. Not the cleanest, but will do for now -->
            <HeadlessMenuItem
              v-for="item in submenu"
              :key="item.name"
              v-slot="{ close }"
            >
              <div>
                <TheNuxtLink
                  v-if="item.to"
                  @click="close"
                  :to="item.to"
                  :target="item.target"
                  class="py-2 px-4 group flex w-full items-center dark:hover:bg-neutral-800 dark:hover:text-neutral-100 dark:active:bg-neutral-700 dark:active:text-white hover:bg-neutral-100 hover:text-neutral-900 active:bg-neutral-200 active:text-black whitespace-nowrap"
                >
                  <component
                    v-if="item.icon"
                    :is="item.icon"
                    class="mr-3 flex-shrink-0"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </TheNuxtLink>
                <button
                  v-else-if="item.action"
                  @click="(e) => (item.action!(e), close())"
                  class="py-2 px-4 group flex w-full items-center dark:hover:bg-neutral-800 dark:active:bg-neutral-700 hover:bg-neutral-100 active:bg-neutral-200"
                >
                  <component
                    v-if="item.icon"
                    :is="item.icon"
                    class="mr-3 flex-shrink-0"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </button>
              </div>
            </HeadlessMenuItem>
          </div>
        </HeadlessMenuItems>
      </transition>
    </div>
  </HeadlessMenu>
</template>

<script setup lang="ts">
import {
  IconBrush,
  IconCoin,
  IconFlask,
  IconLogin,
  IconLogout,
  IconPencil,
  IconQuestionMark,
  IconServerCog,
  IconSettings,
  IconUserX,
} from "@tabler/icons-vue";
import { parseUserForDisplay } from "~/utils/display/user";
import type { NavigationItem } from "~/types/navigation";

const nuxtApp = useNuxtApp();
const authStore = nuxtApp.$auth();
const route = useRoute();
const { t } = nuxtApp.$i18n.global;

const href = useHref();
const { orgs } = useUserMemberOwnerOrgs();
const mappedLocale = useMappedLocale();

const userEntity = computed(() => authStore?.userEntity);

const {
  username,
  displayName,
  href: userHref,
  official,
  verified,
} = parseUserForDisplay(userEntity, "small");

const dropdownHeadlessMenu = computed<NavigationItem[][]>(() => {
  if (!authStore?.user)
    return [
      [
        {
          name: t("layouts.default.logIn"),
          action: goToLogin,
          icon: IconLogin,
        },
        {
          name: t("layouts.default.signUp"),
          action: goToSignup,
          icon: IconPencil,
        },
      ],
      [
        {
          name: t("layouts.default.settings"),
          to: href.value.settings.sitePreferences,
          icon: IconSettings,
        },
      ],
    ];

  const hasSingleOrg = orgs.value.length === 1;

  const base: NavigationItem[][] = [
    [
      {
        name: t("components.theDesktopNavbarTop.dropdownSubscriptions"),
        to: href.value.settings.subscription.history,
        icon: IconCoin,
      },
      {
        name: "NamiComi Studio",
        to: hasSingleOrg
          ? href.value.studio.org(orgs.value[0].id).dashboard
          : href.value.studio.index,
        icon: IconBrush,
      },
      hasAdminAccess(authStore?.user) && {
        name: "Admin",
        to: href.value.admin.dashboard,
        icon: IconServerCog,
      },
      //{ name: 'Purchases and memberships', to: `/${ mappedLocale.value }/subscriptions`, icon: 'solid.dollar' },
    ].filter(Boolean) as NavigationItem[],
  ];
  base.push(
    [
      {
        name: t("layouts.default.settings"),
        to: href.value.settings.profile,
        icon: IconSettings,
      },
      {
        name: t("layouts.default.help"),
        to: href.value.external.helpCenter,
        icon: IconQuestionMark,
        target: "_blank",
      },
      hasAdminAccess(authStore?.user) && {
        name: "Tests",
        to: href.value.test.index,
        icon: IconFlask,
      },
    ].filter(Boolean) as NavigationItem[],
    [
      {
        name: t("layouts.default.logOut"),
        action: (e) => goToSignout(e),
        icon: IconLogout,
      },
    ],
  );
  return base;
});

function goToLogin(e: MouseEvent) {
  e.preventDefault();
  authStore?.login({
    redirectBackTo: route.fullPath,
    locale: mappedLocale.value,
  });
}

// Until we have a signup page URL in the OpenID configuration, this is the same as login
function goToSignup(e: MouseEvent) {
  e.preventDefault();
  authStore?.login({
    redirectBackTo: route.fullPath,
    locale: mappedLocale.value,
  });
}

function goToSignout(e: MouseEvent) {
  e.preventDefault();
  authStore?.logout({
    locale: mappedLocale.value,
  });
}
</script>
