<template>
  <div v-if="titles.length > 0">
    <p class="text-xl font-medium mb-4">Titles</p>
    <TitlesView :meta="meta" :titles="parsedTitles" />
    <NamiDivider />
  </div>
</template>

<script setup lang="ts">
import type { TitleEntity, Meta } from "~/src/api";
import { parseTitleForDisplay } from "~/utils/display/title";

const props = defineProps<{
  titles: TitleEntity[];
  meta: Meta;
}>();

const parsedTitles = computed(() =>
  props.titles.map((title) => {
    const parsed = parseTitleForDisplay(title, "small");
    return {
      ...parsed,
      href: parsed.href + "?s=qs",
    };
  }),
);
</script>
