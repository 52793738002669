<template>
  <TheNuxtLink :to="href.home" class="flex items-center">
    <img
      class="block h-8 w-8"
      src="~/assets/namicomi-logo.svg"
      :alt="siteName"
    />
    <div class="ml-2 font-[Roboto] -space-y-2">
      <p class="font-bold text-xl text-nami-deep-blue dark:text-neutral-100">
        {{ siteName }}
      </p>
      <div v-if="isMiraiEnabled" class="flex items-center gap-1">
        <div class="flex-grow h-[1px] bg-current"></div>
        <p class="text-sm text-nami-deep-blue dark:text-neutral-100">mirai</p>
      </div>
    </div>
  </TheNuxtLink>
</template>

<script setup lang="ts">
defineProps<{
  siteName: string;
}>();

const href = useHref();
const { isMiraiEnabled } = useMirai();
</script>
