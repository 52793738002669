<template>
  <div class="my-4 text-center">
    {{ $t("components.theSearchEnterQueryPrompt.text") }}
  </div>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp();
const $t = nuxtApp.$i18n.global.t;
</script>
