<template>
  <ContextMenu
    v-if="isLoggedIn"
    defaultPosition="bottom-left"
    closeOnOutsideClick
    :customPosition="
      md
        ? { top: '3.75rem' }
        : {
            left: '0.75rem',
            right: '0.75rem',
            top: '3.75rem',
            bottom: '8rem',
          }
    "
  >
    <template #facing="{ isOpen, toggle }">
      <div class="relative">
        <NamiButton
          buttonType="secondary"
          :text="!isOpen"
          pill
          noWaves
          :icon="IconBell"
          @buttonClick="() => md && onButtonClick(toggle)"
          :href="md ? undefined : `/${mappedLocale}/notifications`"
        />
        <span
          v-if="totalNewMessages.total > 0"
          class="absolute select-none top-1 bg-neutral-900 dark:bg-white text-white dark:text-black rounded-full pr-1 pl-[calc(0.25rem+1px)] py-0 pointer-events-none text-xs font-bold flex items-center justify-center"
          :class="totalNewMessages.total > 9 ? 'right-0' : 'right-1'"
        >
          {{ totalNewMessages.formatted }}
        </span>
      </div>
    </template>
    <template #menu="{ close }">
      <div
        class="p-4 rounded-md bg-white dark:bg-neutral-900 overflow-auto relative overflow-y-scroll"
        :class="{
          'w-[42rem] max-h-[36rem]': md,
          'w-full max-h-full': !md,
        }"
      >
        <TheNotificationContent @navigate="close" />
      </div>
    </template>
  </ContextMenu>
</template>

<script setup lang="ts">
import { Notifications } from "~/src/api";
import { IconBell } from "@tabler/icons-vue";
import { injectNotificationsContext } from "../app/context/sources/notifications/context";
import {
  useInternalNotificationsPermissionStatusStorage,
  InternalNotificationPermissionStatus,
} from "~/composables/local/internalNotificationsPermissionStatusStorage";

const nuxtApp = useNuxtApp();
const authStore = nuxtApp.$auth();
const { begForEnablePushFunction } = injectNotificationsContext();
const internalNotificationsPermissionStatusStorage =
  useInternalNotificationsPermissionStatusStorage();
const mappedLocale = computed(() =>
  reverseLocaleMap(nuxtApp.$i18n.global.locale.value),
);

const locale = nuxtApp.$i18n.global.locale;

const numFormatter = useNumberFormatter(locale);

const { md } = useBreakpoints();
const isLoggedIn = computed(() => !!authStore?.userEntity);

const { data: newNotifications } = useAsyncData(
  "notifications-number",
  async () => {
    if (!isLoggedIn.value) {
      return 0;
    }

    const token = await getTokenOrThrow();

    const total = await Notifications.find(
      { isRead: false, limit: 0, grouped: false },
      token,
    ).then(({ meta }) => meta.total);

    return total;
  },
  {
    watch: [isLoggedIn],
    default: () => 0,
  },
);

const totalNewMessages = computed(() => ({
  total: newNotifications.value,
  formatted: numFormatter.value(newNotifications.value),
}));

async function onButtonClick(toggleContextMenu: () => void) {
  if (
    internalNotificationsPermissionStatusStorage.value !==
    InternalNotificationPermissionStatus.Default
  ) {
    return toggleContextMenu();
  }

  await begForEnablePushFunction.value?.();

  toggleContextMenu();
}
</script>
