<template>
  <ClientOnly>
    <div v-if="windowWidth >= 1024" class="flex-grow">
      <OverlayVertical v-model="overlay" position="bottom">
        <template #visible>
          <div
            ref="inputContainer"
            class="ml-auto transition-[max-width]"
            :class="{
              'max-w-[50%]': !isFocused,
              'max-w-[100%]': isFocused,
            }"
          >
            <div class="relative">
              <NamiInput
                v-model="search"
                style="min-height: 2.5rem"
                @focus="handleFocus"
                @blur="handleBlur"
                block
                :placeholder="$t('components.theSearchBar.inputPlaceholder')"
                pill
              />
              <div class="absolute right-2 top-[0.30rem]">
                <NamiButton
                  :disabled="$route.path === href.title.search"
                  button-type="secondary"
                  title="Advanced Title Search"
                  small
                  pill
                  text
                  :href="href.title.search"
                  :icon="IconAdjustmentsHorizontal"
                />
              </div>
            </div>
          </div>
        </template>
        <template #overlay>
          <div
            ref="overlayContainer"
            class="shadow-xl relative max-h-[32rem] overflow-auto top-2 rounded-md p-4 border border-neutral-300 dark:border-neutral-600 bg-neutral-50 dark:bg-neutral-900"
          >
            <TheSearchEnterQueryPrompt v-if="search === ''" />
            <NamiLoading v-else-if="pending" />
            <TheSearchNoResults v-else-if="hasNoResults" />
            <template v-else-if="data">
              <TheSearchResultsTitles
                :titles="data.titles.data"
                :meta="data.titles.meta"
              />
              <TheSearchResultsOrgs
                class="my-4"
                :orgs="data.orgs.data"
                @close="reset"
              />
              <TheSearchResultsUsers
                class="my-4"
                :users="data.users.data"
                @close="reset"
              />
            </template>
          </div>
        </template>
      </OverlayVertical>
    </div>
    <div v-else>
      <NamiButton
        buttonType="secondary"
        text
        pill
        @buttonClick="mobileSearchOpen = true"
        :icon="IconSearch"
      />
    </div>
    <Teleport v-if="windowWidth < 1024" to="body">
      <div
        class="fixed w-full h-full pointer-events-none overflow-auto z-[52] top-0 left-0 dark:text-white"
      >
        <TransitionAutoHeight v-model="mobileSearchOpen">
          <div
            class="pointer-events-auto p-5 bg-neutral-50 dark:bg-neutral-900 rounded-b-md shadow-xl space-y-4"
          >
            <div class="grid grid-cols-[1fr_2.5rem] gap-4">
              <div class="relative">
                <NamiInput
                  v-model="search"
                  style="min-height: 2.5rem"
                  @focus="handleFocus"
                  @blur="handleBlur"
                  block
                  :placeholder="$t('components.theSearchBar.inputPlaceholder')"
                  pill
                />
                <div class="absolute right-2 top-[0.30rem]">
                  <NamiButton
                    :disabled="$route.path === href.title.search"
                    button-type="secondary"
                    title="Advanced Title Search"
                    small
                    pill
                    text
                    :href="href.title.search"
                    :icon="IconAdjustmentsHorizontal"
                  />
                </div>
              </div>
              <NamiButton
                buttonType="secondary"
                text
                pill
                @buttonClick="mobileSearchOpen = false"
                :icon="IconChevronUp"
              />
            </div>
            <TheSearchEnterQueryPrompt v-if="search === ''" />
            <NamiLoading v-else-if="pending" />
            <TheSearchNoResults v-else-if="hasNoResults" />
            <template v-else-if="data">
              <TheSearchResultsTitles
                :titles="data.titles.data"
                :meta="data.titles.meta"
              />
              <TheSearchResultsOrgs
                class="my-4"
                :orgs="data.orgs.data"
                @close="reset"
              />
              <TheSearchResultsUsers
                class="my-4"
                :users="data.users.data"
                @close="reset"
              />
            </template>
          </div>
        </TransitionAutoHeight>
      </div>
      <div
        class="lg:hidden fixed z-[51] left-0 top-0 w-full h-full transition"
        :class="{
          'bg-black/25 dark:bg-black/50 pointer-events-auto': mobileSearchOpen,
          'pointer-events-none': !mobileSearchOpen,
        }"
        @click="mobileSearchOpen = false"
      ></div>
    </Teleport>
  </ClientOnly>
</template>

<script setup lang="ts">
import {
  IconSearch,
  IconChevronUp,
  IconAdjustmentsHorizontal,
} from "@tabler/icons-vue";
import { useWindowSize } from "@vueuse/core";
import { Title, Organization, User } from "~/src/api";

const nuxtApp = useNuxtApp();
const authStore = nuxtApp.$auth();
const route = useRoute();
const $t = nuxtApp.$i18n.global.t;

const href = useHref();

const user = computed(() => authStore?.user);
const search = ref((route.query.s as string) ?? "");
const mobileSearchOpen = ref(false);
const isFocused = ref(false);
const overlay = ref(false);

const { pending, data, error, refresh } = useAsyncData("search", async () => {
  if (search.value === "") {
    return null;
  }

  await authStore?.waitUntilRefreshIsComplete();

  const token = await authStore?.getToken();

  const [titles, orgs, users] = await Promise.all([
    Title.fuzzySearch({
      title: search.value,
      limit: 5,
      includes: ["cover_art"],
    }),
    Organization.fuzzySearch({ name: search.value, limit: 5 }),
    token
      ? User.fuzzySearch(
          {
            names: search.value,
            limit: 5,
            includes: ["profile_frame", "badge"],
          },
          token,
        )
      : { data: [], meta: { total: 0, limit: 5, offset: 0 } },
  ]);

  return {
    titles,
    orgs,
    users,
  };
});

let timer: NodeJS.Timeout | null = null;

watch(search, () => {
  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(() => {
    refresh();
  }, 300);
});

const hasNoResults = computed(() => {
  if (!data.value) {
    return true;
  }

  return (
    data.value.orgs.meta.total === 0 &&
    data.value.titles.meta.total === 0 &&
    data.value.users.meta.total === 0
  );
});

const { width: windowWidth } = useWindowSize();

const inputContainer = ref<HTMLDivElement>();
const overlayContainer = ref<HTMLDivElement>();

const tabs = computed(() => {
  const tabArray = [
    { text: $t("components.theSearchBar.tabTitles"), value: "title" },
    { text: $t("components.theSearchBar.tabOrgs"), value: "organization" },
    user.value && { text: "Users", value: "user" },
  ].filter(<T,>(x: T | boolean | null | undefined): x is T => !!x);

  return tabArray;
});

function handleFocus() {
  isFocused.value = true;
  overlay.value = true;
  registerListeners();
}

function handleBlur() {}

function reset() {
  search.value = "";
  isFocused.value = false;
  mobileSearchOpen.value = false;
  overlay.value = false;
  unregisterListeners();
}

watch(
  () => route.path,
  () => reset(),
);

function handleClick(e: MouseEvent | TouchEvent) {
  if (!overlayContainer.value || !inputContainer.value) return;

  if (
    isClickInsideElements(e, [overlayContainer.value, inputContainer.value])
  ) {
    return;
  }

  isFocused.value = false;
  overlay.value = false;
}

function registerListeners() {
  document.addEventListener("click", handleClick);
}

function unregisterListeners() {
  document.removeEventListener("click", handleClick);
}
</script>
