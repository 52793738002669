<template>
  <nav
    class="z-40 flex flex-col justify-end transition-all bg-white/80 dark:bg-neutral-900/80 text-neutral-800 dark:text-neutral-200 backdrop-blur-lg pointer-events-auto"
    :class="{
      'w-[calc(100%-10px)]': offsetContent,
      'w-full': !offsetContent,
    }"
  >
    <div
      class="flex h-16 justify-between max-w-[3860px] mx-auto w-full home-carousel"
    >
      <!-- Navbar left, burger + logo -->
      <div class="relative z-40 flex">
        <div class="flex flex-shrink-0 items-center">
          <TheWordmark :site-name />
          <TheTopNavMenu />
        </div>
      </div>

      <!-- Navbar right, search, guest settings + signup/login -->
      <div
        class="relative z-10 flex items-center justify-end lg:w-128 xl:w-176 gap-2"
      >
        <TheSearchBar />

        <BadgeIndicator
          v-if="authStore?.user"
          :show="thereIsAConversationWithUnreadMessages"
        >
          <NamiButton
            button-type="secondary"
            pill
            text
            :href="href.messages.index"
            :icon="IconMail"
          />
        </BadgeIndicator>

        <ClientOnly>
          <TheNotificationButton />
        </ClientOnly>

        <!-- User dropdown -->
        <NavUserDropdown class="px-2" />
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { IconMail } from "@tabler/icons-vue";
import { injectTwilioContext } from "../app/context/sources/twilio/context";
import BadgeIndicator from "../badge/BadgeIndicator.vue";

defineProps<{
  siteName: string;
  siteSubtitle?: string;
}>();

const href = useHref();
const nuxtApp = useNuxtApp();
const authStore = nuxtApp.$auth();
const appStore = nuxtApp.$app();

const { primaryTwilioClient } = injectTwilioContext();
const { conversationsMessageData } = primaryTwilioClient;
const thereIsAConversationWithUnreadMessages = computed(() =>
  Object.values(conversationsMessageData).some(
    (d) =>
      d.lastMessage &&
      d.lastMessageReadIndex !== null &&
      d.lastMessage.index > d.lastMessageReadIndex,
  ),
);

const offsetContent = computed(
  () => appStore?.state.offsetContentOnWebkitBecauseScrollIsDisabled,
);
</script>
