<template>
  <HeadlessTransitionRoot as="template" :show="modelValue">
    <HeadlessDialog
      as="div"
      class="relative z-40 sm:hidden"
      @close="emit('update:modelValue', false)"
    >
      <HeadlessTransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-neutral-500 bg-opacity-50" />
      </HeadlessTransitionChild>

      <div class="fixed inset-0 z-40 flex">
        <HeadlessTransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <HeadlessDialogPanel
            class="relative flex max-w-[16rem] flex-1 flex-col bg-white pb-4"
          >
            <HeadlessTransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-0">
                <button class="outline-none" />
                <!-- weird bug, class="outline-none" is a workaround -->
              </div>
            </HeadlessTransitionChild>
            <div class="fixed inset-y-0 flex w-64 flex-col">
              <div
                class="flex flex-grow flex-col overflow-y-auto border-r border-neutral-300 dark:border-neutral-800 bg-white dark:bg-neutral-900"
              >
                <div
                  class="flex flex-shrink-0 items-center px-4 shadow h-16 fixed set-x-0 top-0 w-64 sm:shadow-none dark:bg-black border-r border-neutral-300 dark:border-neutral-700"
                >
                  <img
                    class="block h-8 w-8"
                    src="~/assets/namicomi-logo.svg"
                    alt="NamiComi"
                  />
                  <p
                    class="ml-4 font-[Roboto] font-bold text-xl text-nami-deep-blue dark:text-neutral-100"
                  >
                    {{ nav.site }}
                  </p>
                </div>

                <div class="pt-16 flex flex-grow flex-col">
                  <nav class="flex-1 space-y-1 px-2 pt-2" aria-label="Sidebar">
                    <template v-for="item in nav.items" :key="item.name">
                      <div v-if="!item.children">
                        <TheNuxtLink
                          :to="item.href"
                          @click="emit('changeNav', item.href!)"
                          class="group w-full flex items-center pl-3 py-2 font-medium rounded-md"
                          :class="
                            (
                              item.href
                                ? item.noNestingLink
                                  ? $route.path === item.href
                                  : $route.path.startsWith(item.href)
                                : undefined
                            )
                              ? 'active'
                              : 'inactive'
                          "
                        >
                          <component
                            v-if="item.icon"
                            :is="item.icon"
                            class="mr-3 flex-shrink-0"
                            aria-hidden="true"
                          />
                          {{ item.name }}
                        </TheNuxtLink>
                      </div>
                      <HeadlessDisclosure
                        as="div"
                        v-else
                        class="space-y-1"
                        :default-open="item.defaultOpen"
                        v-slot="{ open }"
                      >
                        <HeadlessDisclosureButton
                          :class="[
                            open
                              ? 'text-neutral-700 dark:text-neutral-300'
                              : 'text-neutral-500',
                            'hover:bg-neutral-100 hover:text-neutral-700 dark:hover:bg-neutral-800 dark:hover:text-neutral-300 group w-full flex items-center pl-3 pr-1 py-2 text-left font-medium rounded-md focus:outline-none',
                          ]"
                        >
                          <component
                            v-if="item.icon"
                            :is="item.icon"
                            class="mr-3 flex-shrink-0"
                            aria-hidden="true"
                          />
                          <span class="flex-1">{{ item.name }}</span>
                          <svg
                            :class="[
                              open
                                ? 'text-neutral-700 rotate-90'
                                : 'text-neutral-500',
                              'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-neutral-700',
                            ]"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                          >
                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                          </svg>
                        </HeadlessDisclosureButton>
                        <HeadlessDisclosurePanel class="space-y-1">
                          <HeadlessDisclosureButton
                            v-for="subItem in item.children"
                            :key="subItem.name"
                            as="div"
                          >
                            <TheNuxtLink
                              :to="subItem.href"
                              @click="emit('changeNav', subItem.href!)"
                              active-class="bg-neutral-200 text-neutral-900 dark:bg-neutral-800 dark:text-neutral-100 dark:text-neutral-100"
                              class="hover:bg-neutral-100 dark:hover:!bg-neutral-800 text-neutral-500 hover:text-neutral-700 dark:hover:!text-neutral-300 group w-full flex items-center pl-12 pr-1 py-2 text-left font-medium rounded-md focus:outline-none"
                            >
                              {{ subItem.name }}
                            </TheNuxtLink>
                          </HeadlessDisclosureButton>
                        </HeadlessDisclosurePanel>
                      </HeadlessDisclosure>
                    </template>
                  </nav>
                </div>

                <footer
                  class="flex items-center justify-center p-4 border-t border-neutral-300 dark:border-neutral-800 dark:text-neutral-100"
                >
                  <div class="">
                    &copy; {{ new Date().getFullYear() }} NamiComi
                  </div>
                </footer>
              </div>
            </div>
          </HeadlessDialogPanel>
        </HeadlessTransitionChild>
        <div class="w-14 flex-shrink-0" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<script setup lang="ts">
import type { NestedNavigationItem } from "~/types/navigation";

export type NavItem = {
  current?: boolean;
} & NestedNavigationItem;

export interface Props {
  modelValue: boolean;
  nav: {
    site: string;
    items: NavItem[];
  };
}

interface Events {
  (e: "update:modelValue", v: Props["modelValue"]): void;
  (e: "changeNav", v: string): void;
}

defineProps<Props>();
const emit = defineEmits<Events>();
</script>

<style scoped>
.active {
  @apply bg-neutral-200 text-neutral-900 dark:bg-neutral-800 dark:text-neutral-100;
}

.inactive {
  @apply hover:bg-neutral-100 dark:hover:!bg-neutral-800 text-neutral-500 hover:text-neutral-700 dark:hover:!text-neutral-300;
}
</style>
